import moment from "moment";
import momentTZ from "moment-timezone";
import { useEffect, useState } from "react";
var CryptoJS = require("crypto-js");
export const isRunningInRNWebView =
  typeof window !== "undefined" && window.ReactNativeWebView ? true : false;

const defaultTimezone = "America/New_York";
export const appVersion = "0.2.5";
export var localDate = momentTZ("21:05", "HH:mm", defaultTimezone);
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}
export const positiveEnforcements = [
  "Valiant!",
  "Legendary!",
  "Majestic!",
  "Brilliant!",
  "Bravo!",
  "Impressive!",
  "Nailed it!",
  "Right on target!",
  "Well done!",
  "Spot on!",
  "Excellent work!",
];
export const countryDetails = [
  {
    countryName: "Argentina",
    phoneCountryShortForm: "AR",
    phoneCountryCode: "+54",
    emoji: "🇦🇷",
  },
  {
    countryName: "Australia",
    phoneCountryShortForm: "AU",
    phoneCountryCode: "+61",
    emoji: "🇦🇺",
  },
  {
    countryName: "Austria",
    phoneCountryShortForm: "AT",
    phoneCountryCode: "+43",
    emoji: "🇦🇹",
  },
  {
    countryName: "Belgium",
    phoneCountryShortForm: "BE",
    phoneCountryCode: "+32",
    emoji: "🇧🇪",
  },
  {
    countryName: "Brazil",
    phoneCountryShortForm: "BR",
    phoneCountryCode: "+55",
    emoji: "🇧🇷",
  },
  {
    countryName: "Bulgaria",
    phoneCountryShortForm: "BG",
    phoneCountryCode: "+359",
    emoji: "🇧🇬",
  },
  {
    countryName: "Canada",
    phoneCountryShortForm: "CA",
    phoneCountryCode: "+1",
    emoji: "🇨🇦",
  },
  {
    countryName: "Chile",
    phoneCountryShortForm: "CL",
    phoneCountryCode: "+56",
    emoji: "🇨🇱",
  },
  {
    countryName: "China",
    phoneCountryShortForm: "CN",
    phoneCountryCode: "+86",
    emoji: "🇨🇳",
  },
  {
    countryName: "Croatia",
    phoneCountryShortForm: "HR",
    phoneCountryCode: "+385",
    emoji: "🇭🇷",
  },
  {
    countryName: "Cyprus",
    phoneCountryShortForm: "CY",
    phoneCountryCode: "+357",
    emoji: "🇨🇾",
  },
  {
    countryName: "Czech Republic",
    phoneCountryShortForm: "CZ",
    phoneCountryCode: "+420",
    emoji: "🇨🇿",
  },
  {
    countryName: "Denmark",
    phoneCountryShortForm: "DK",
    phoneCountryCode: "+45",
    emoji: "🇩🇰",
  },
  {
    countryName: "Estonia",
    phoneCountryShortForm: "EE",
    phoneCountryCode: "+372",
    emoji: "🇪🇪",
  },
  {
    countryName: "Finland",
    phoneCountryShortForm: "FI",
    phoneCountryCode: "+358",
    emoji: "🇫🇮",
  },
  {
    countryName: "France",
    phoneCountryShortForm: "FR",
    phoneCountryCode: "+33",
    emoji: "🇫🇷",
  },
  {
    countryName: "Germany",
    phoneCountryShortForm: "DE",
    phoneCountryCode: "+49",
    emoji: "🇩🇪",
  },
  {
    countryName: "Greece",
    phoneCountryShortForm: "GR",
    phoneCountryCode: "+30",
    emoji: "🇬🇷",
  },
  {
    countryName: "Hungary",
    phoneCountryShortForm: "HU",
    phoneCountryCode: "+36",
    emoji: "🇭🇺",
  },
  {
    countryName: "India",
    phoneCountryShortForm: "IN",
    phoneCountryCode: "+91",
    emoji: "🇮🇳",
  },
  {
    countryName: "Indonesia",
    phoneCountryShortForm: "ID",
    phoneCountryCode: "+62",
    emoji: "🇮🇩",
  },
  {
    countryName: "Ireland",
    phoneCountryShortForm: "IE",
    phoneCountryCode: "+353",
    emoji: "🇮🇪",
  },
  {
    countryName: "Italy",
    phoneCountryShortForm: "IT",
    phoneCountryCode: "+39",
    emoji: "🇮🇹",
  },
  {
    countryName: "Japan",
    phoneCountryShortForm: "JP",
    phoneCountryCode: "+81",
    emoji: "🇯🇵",
  },
  {
    countryName: "Latvia",
    phoneCountryShortForm: "LV",
    phoneCountryCode: "+371",
    emoji: "🇱🇻",
  },
  {
    countryName: "Lithuania",
    phoneCountryShortForm: "LT",
    phoneCountryCode: "+370",
    emoji: "🇱🇹",
  },
  {
    countryName: "Luxembourg",
    phoneCountryShortForm: "LU",
    phoneCountryCode: "+352",
    emoji: "🇱🇺",
  },
  {
    countryName: "Malaysia",
    phoneCountryShortForm: "MY",
    phoneCountryCode: "+60",
    emoji: "🇲🇾",
  },
  {
    countryName: "Malta",
    phoneCountryShortForm: "MT",
    phoneCountryCode: "+356",
    emoji: "🇲🇹",
  },
  {
    countryName: "Mexico",
    phoneCountryShortForm: "MX",
    phoneCountryCode: "+52",
    emoji: "🇲🇽",
  },
  {
    countryName: "Netherlands",
    phoneCountryShortForm: "NL",
    phoneCountryCode: "+31",
    emoji: "🇳🇱",
  },
  {
    countryName: "New Zealand",
    phoneCountryShortForm: "NZ",
    phoneCountryCode: "+64",
    emoji: "🇳🇿",
  },
  {
    countryName: "Norway",
    phoneCountryShortForm: "NO",
    phoneCountryCode: "+47",
    emoji: "🇳🇴",
  },
  {
    countryName: "Pakistan",
    phoneCountryShortForm: "PK",
    phoneCountryCode: "+92",
    emoji: "🇵🇰",
  },
  {
    countryName: "Philippines",
    phoneCountryShortForm: "PH",
    phoneCountryCode: "+63",
    emoji: "🇵🇭",
  },
  {
    countryName: "Poland",
    phoneCountryShortForm: "PL",
    phoneCountryCode: "+48",
    emoji: "🇵🇱",
  },
  {
    countryName: "Portugal",
    phoneCountryShortForm: "PT",
    phoneCountryCode: "+351",
    emoji: "🇵🇹",
  },
  {
    countryName: "Romania",
    phoneCountryShortForm: "RO",
    phoneCountryCode: "+40",
    emoji: "🇷🇴",
  },
  {
    countryName: "Russia",
    phoneCountryShortForm: "RU",
    phoneCountryCode: "+7",
    emoji: "🇷🇺",
  },
  {
    countryName: "Serbia",
    phoneCountryShortForm: "RS",
    phoneCountryCode: "+381",
    emoji: "🇷🇸",
  },
  {
    countryName: "Singapore",
    phoneCountryShortForm: "SG",
    phoneCountryCode: "+65",
    emoji: "🇸🇬",
  },
  {
    countryName: "Slovakia",
    phoneCountryShortForm: "SK",
    phoneCountryCode: "+421",
    emoji: "🇸🇰",
  },
  {
    countryName: "Slovenia",
    phoneCountryShortForm: "SI",
    phoneCountryCode: "+386",
    emoji: "🇸🇮",
  },
  {
    countryName: "South Africa",
    phoneCountryShortForm: "ZA",
    phoneCountryCode: "+27",
    emoji: "🇿🇦",
  },
  {
    countryName: "Spain",
    phoneCountryShortForm: "ES",
    phoneCountryCode: "+34",
    emoji: "🇪🇸",
  },
  {
    countryName: "Sweden",
    phoneCountryShortForm: "SE",
    phoneCountryCode: "+46",
    emoji: "🇸🇪",
  },
  {
    countryName: "Switzerland",
    phoneCountryShortForm: "CH",
    phoneCountryCode: "+41",
    emoji: "🇨🇭",
  },
  {
    countryName: "Turkey",
    phoneCountryShortForm: "TR",
    phoneCountryCode: "+90",
    emoji: "🇹🇷",
  },
  {
    countryName: "Thailand",
    phoneCountryShortForm: "TH",
    phoneCountryCode: "+66",
    emoji: "🇹🇭",
  },
  {
    countryName: "United Arab Emirates",
    phoneCountryShortForm: "AE",
    phoneCountryCode: "+971",
    emoji: "🇦🇪",
  },
  {
    countryName: "United Kingdom",
    phoneCountryShortForm: "GB",
    phoneCountryCode: "+44",
    emoji: "🇬🇧",
  },
  {
    countryName: "United States",
    phoneCountryShortForm: "US",
    phoneCountryCode: "+1",
    emoji: "🇺🇸",
  },
];

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
export const useForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const onArrayChange = (event) => {
    // const newValues = [...values];
    // event.preventDefault();
    // event.stopPropagation();

    var list = [...values[event.target.name]];

    let index = 0;

    if (list) {
      if (event.target.checked) {
        if (!list.includes(event.target.value)) {
          list = [...list, event.target.value];
        }
      } else if (list.length === 1) {
        setValues({ ...values, [event.target.name]: [] });
        return;
      } else {
        index = list.indexOf(event.target.value);
        if (index > -1) {
          list.splice(index, 1);
        }
      }

      // TODO: instructions out all console.logs
      list.sort();
      setValues({ ...values, [event.target.name]: list });
    }
  };

  const onChange = (event, convertValToNumber) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      event.target.name.toLowerCase().includes("price") ||
      event.target.name === "amountRequested"
    ) {
      setValues({
        ...values,
        [event.target.name]: Math.round(event.target.value * 100),
      });
      return;
    } else if (
      event.target.type === "number" ||
      event.target.type === "range"
    ) {
      const numberValue = parseInt(event.target.value, 10) || 0;
      if (event.target.max && event.target.max < numberValue) {
        setValues({
          ...values,
          [event.target.name]: parseInt(event.target.max, 10),
        });
        return;
      } else if (event.target.min && event.target.min > numberValue) {
        setValues({
          ...values,
          [event.target.name]: parseInt(event.target.min, 10),
        });
        return;
      }
      setValues({
        ...values,
        [event.target.name]: numberValue,
      });
    } else if (event.target.type === "checkbox") {
      if (event.target.checked) {
        setValues({ ...values, [event.target.name]: true });
      } else {
        setValues({ ...values, [event.target.name]: false });
      }
    } else if (event.target.name === "typeOfDiscount") {
      var amountOff = 0;
      if (event.target.value === "0") {
        amountOff = 1000;
      } else if (event.target.value === "1") {
        amountOff = 10;
      } else {
        amountOff = (3 / 23) * 100;
      }
      setValues({
        ...values,
        [event.target.name]: parseInt(event.target.value) || 0,
        amountOff,
      });
    } else if (convertValToNumber) {
      const numberValue = parseFloat(event.target.value);
      if (event.target.max && event.target.max < numberValue) {
        setValues({
          ...values,
          [event.target.name]: parseInt(event.target.max, 10),
        });
        return;
      } else if (event.target.min && event.target.min > numberValue) {
        setValues({
          ...values,
          [event.target.name]: parseInt(event.target.min, 10),
        });
        return;
      }
      setValues({
        ...values,
        [event.target.name]: numberValue,
      });
    } else if (
      event.target.name === "handle" ||
      event.target.name === "username" ||
      event.target.name.includes("Handle") ||
      event.target.name.includes("Code")
    ) {
      var editedText = event.target.value.replace(
        "https://www.reddit.com/",
        ""
      );
      editedText = editedText.replace("https://www.twitter.com/", "");
      editedText = editedText.replace("https://www.instagram.com/", "");
      editedText = editedText.replace("https://www.tiktok.com/", "");

      editedText = editedText.replace("https://reddit.com/", "");
      editedText = editedText.replace("https://twitter.com/", "");
      editedText = editedText.replace("https://instagram.com/", "");
      editedText = editedText.replace("https://tiktok.com/", "");

      editedText = editedText.replace("https://www.", "");
      editedText = editedText.replace("http://www.", "");
      editedText = editedText.replace("https://", "");
      editedText = editedText.replace("http://", "");

      editedText = editedText.replace("u/", "");
      editedText = editedText.replace("@", "");
      editedText = editedText.replace("user/", "");
      editedText = editedText.replace('"', "");
      editedText = editedText.replace("'", "");
      editedText = editedText.replace(/\s/g, "");

      setValues({
        ...values,
        [event.target.name]: editedText,
      });
    } else if (
      event.target.name.toLowerCase().includes("link") ||
      event.target.name === "businessWeb"
    ) {
      setValues({
        ...values,
        [event.target.name]: event.target.value.replace(/(^\w+:|^)\/\//, ""),
      });
    } else if (event.target.name === "shouldReceiveGameReminders") {
      setValues({
        ...values,
        [event.target.name]: event.target.value === "true" ? true : false,
      });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  const onChangeMedia = (mediaUploadTitle, mediaUploadFile, fileType) => {
    setValues({
      ...values,

      [mediaUploadTitle]: mediaUploadFile,
      [mediaUploadTitle + "Type"]: fileType.includes("image") ? 1 : 0,
    });
  };

  const onChangeNumber = (name, number) => {
    const numberValue = parseInt(number, 10) || 0;

    setValues({
      ...values,
      [name]: numberValue,
    });
  };

  const onChangeRadio = (name, text) => {
    setValues({
      ...values,
      [name]: text,
      acceptedTermsAndConditions:
        name === "acceptedTermsAndConditions"
          ? text
          : name === "selectedTokenActionOption" && text !== values[name]
          ? "View"
          : values.acceptedTermsAndConditions,
    });
  };
  const onEditPrice = (e) => {
    e.preventDefault();
    var rawPriceString = e.target.value;
    var trailingDecimalsRemoved = parseInt(parseFloat(rawPriceString) * 100);

    setValues({
      ...values,
      [e.target.name]: trailingDecimalsRemoved,
    });
  };
  const onSubmit = (event) => {
    event.preventDefault();

    event.stopPropagation();
    callback();
  };

  const onChangePic = (
    picUploadTitle,
    tempPicTitle,
    tempPicUrl,
    picUploadFile,
    digitalGoodType
  ) => {
    setValues({
      ...values,
      oldProfilePicUrl:
        picUploadTitle === "profilePicFile" ? "" : values.oldProfilePicUrl,
      oldBannerPicUrl:
        picUploadTitle === "bannerPicFile" ? "" : values.oldBannerPicUrl,

      mainMediaUrl:
        picUploadTitle === "mainMediaFile" ? "" : values.mainMediaUrl,

      [tempPicTitle]: tempPicUrl,
      [picUploadTitle]: picUploadFile,
      digitalGoodType: digitalGoodType || values.digitalGoodType || "image",

      shouldDeleteMainMediaUrl:
        picUploadTitle === "mainMediaFile" && !tempPicUrl && !picUploadFile
          ? true
          : false,

      shouldDeleteProfilePic:
        picUploadTitle === "profilePicFile" && !tempPicUrl && !picUploadFile
          ? true
          : false,

      shouldDeleteBannerPic:
        picUploadTitle === "bannerPicFile" && !tempPicUrl && !picUploadFile
          ? true
          : false,
    });
  };
  const onChangeCheckbox = (name, value) => {
    var list = values[name] ? [...values[name]] : [];

    let index = 0;
    var somethingIsBeingRemoved = false;
    if (!list.includes(value)) {
      list = [...list, value];
    } else {
      index = list.indexOf(value);

      if (index > -1) {
        list.splice(index, 1);

        somethingIsBeingRemoved = true;
      }
    }

    const everyOptionIsFilledAndUnique = (name, targetQuestionIndex) => {
      var copyOfQuestionsArray = [...values[name]];
      const indexBeingEdited =
        targetQuestionIndex === -1
          ? copyOfQuestionsArray.length - 1
          : targetQuestionIndex;
      if (
        values[name][indexBeingEdited].options &&
        values[name][indexBeingEdited].options.length === 4
      ) {
        for (
          let i = 0;
          i < values[name][indexBeingEdited].options.length;
          ++i
        ) {
          if (!values[name][indexBeingEdited].options[i]) {
            return false;
          }
          if (i !== values[name][indexBeingEdited].options.length - 1) {
            for (
              let j = i + 1;
              j < values[name][indexBeingEdited].options.length;
              ++j
            ) {
              if (
                values[name][indexBeingEdited].options[i] ===
                values[name][indexBeingEdited].options[j]
              ) {
                // setErrors({...errors,[name]:"Duplicate options"})

                return false;
              }
            }
          }
        }
        return true;
      } else {
        return false;
      }
    };

    // TODO: instructions out all console.logs
    if (
      somethingIsBeingRemoved &&
      name === "otherInfoToInclude" &&
      value === "Links" &&
      values.links
    ) {
      var copyOfLinksArray = [...values.links];

      for (let i = 0; i < copyOfLinksArray.length; ++i) {
        const curLink = copyOfLinksArray[i];
        if (!curLink.title && !curLink.link) {
          copyOfLinksArray.splice(i, 1);
        }
      }

      setValues({ ...values, [name]: list, links: copyOfLinksArray });
    } else if (
      somethingIsBeingRemoved &&
      value === "Question" &&
      values.questions
    ) {
      var copyOfQuestionsArray = [...values.questions];

      for (let i = 0; i < copyOfQuestionsArray.length; ++i) {
        const curQuestion = copyOfQuestionsArray[i];
        if (
          !curQuestion.questionInBold &&
          !everyOptionIsFilledAndUnique(name, i)
        ) {
          copyOfQuestionsArray.splice(i, 1);
        }
      }

      setValues({ ...values, [name]: list, questions: copyOfQuestionsArray });
    } else {
      setValues({ ...values, [name]: list });
    }
  };
  return {
    onChange,
    onSubmit,
    // onCounterClick,
    onArrayChange,
    onChangeRadio,
    values,
    onChangeMedia,
    setValues,
    onChangePic,
    onChangeNumber,
    onChangeCheckbox,
    onEditPrice,
    // onDrop,
  };
};

// export const getContentTypeByFile = (fileName) => {
//   var rc = { type: "image/jpeg", extension: ".jpeg" };
//   var fn = fileName.toLowerCase();
//   ".ai,.dwg,.dxf,.dgn,.stl"
//   : name === "gerberFiles"
//   ? ".gbr"
//   : ".doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"

//   if (fn.indexOf(".html") >= 0) {
//     rc.type = "text/html";
//     rc.extension = ".html";
//   } else if (fn.indexOf(".css") >= 0) {
//     rc.type = "text/css";
//     rc.extension = ".css";
//   } else if (fn.indexOf(".json") >= 0) {
//     rc.type = "application/json";
//     rc.extension = ".json";
//   }else if (fn.indexOf(".pdf") >= 0) {
//     rc.type = "application/pdf";
//     rc.extension = ".json";
//   } else if (fn.indexOf(".js") >= 0) {
//     rc.type = "application/x-javascript";
//     rc.extension = ".js";
//   } else if (fn.indexOf(".png") >= 0) {
//     rc.type = "image/png";
//     rc.extension = ".png";
//   } else if (fn.indexOf(".jpg") >= 0) {
//     rc.type = "image/jpg";
//     rc.extension = ".jpg";
//   } else if (fn.indexOf(".gif") >= 0) {
//     rc.type = "image/gif";
//     rc.type = ".gif";
//   }

//   return rc;
// };

export const getNatural = (num) => {
  return num
    .toString()
    .split(".")[0]
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const getDecimal = (num) => {
  return num.toString().split(".")[1];
};

export const getMoneyVersion = (num) => {
  return (num.bidAmount / 100).toFixed(2);
};
export const checkIsDarkMode = () => {
  return (
    window.matchMedia("(prefers-color-scheme: dark)") &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

export const decryptQCipher = (ciphertext) => {
  var bytes = CryptoJS.AES.decrypt(
    ciphertext,
    process.env.REACT_APP_SECRET_QUESTION_KEY
  );
  var originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;
};

export const useWindowSize = () => {
  const isClient = typeof window === "object";

  // function getSize() {
  //   return {
  //     width: isClient ? window.innerWidth : undefined,
  //     height: isClient ? window.innerHeight : undefined,
  //   };
  // }

  const screenSize = {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };

  const [windowSize, setWindowSize] = useState(screenSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      const screenSize = {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      };
      setWindowSize(screenSize);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const getNumOfPlayIconsToShow = (size) => {
  const height = size.height;
  const width = size.width;

  // if (height < 672) {
  if (width >= 640) {
    if (height < 500) {
      return 9;
    } else if (height < 530) {
      return 10;
    } else if (height < 600) {
      return 11;
    } else if (height < 620) {
      return 12;
    } else if (height < 650) {
      return 13;
    } else if (height < 850) {
      return 14;
    } else if (height < 900) {
      return 15;
    } else {
      return 16;
    }
  } else {
    if (height < 500) {
      return 9;
    } else if (height < 530) {
      return 10;
    } else if (height < 580) {
      return 11;
    } else if (height < 620) {
      return 12;
    } else if (height < 700) {
      return 13;
    } else if (height < 750) {
      return 14;
    } else if (height < 800) {
      return 15;
    } else if (height < 820) {
      return 16;
    } else {
      return 17;
    }
  }
  // } else {
  //   if (height < 700) {
  //     return 13;
  //   } else {
  //     return 15;
  //   }
  // }
};

// const SCROLL_UP = "up";
// const SCROLL_DOWN = "down";

// export const useScrollDirection = ({
//   initialDirection,
//   thresholdPixels,
//   off,
// } = {}) => {
//   const [scrollDir, setScrollDir] = useState(initialDirection);

//   useEffect(() => {
//     const threshold = thresholdPixels || 0;
//     let lastScrollY = window.pageYOffset;
//     let ticking = false;

//     const updateScrollDir = () => {
//       const scrollY = window.pageYOffset;

//       if (Math.abs(scrollY - lastScrollY) < threshold) {
//         // We haven't exceeded the threshold
//         ticking = false;
//         return;
//       }
//       setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
//       lastScrollY = scrollY > 0 ? scrollY : 0;
//       ticking = false;
//     };

//     const onScroll = () => {
//       if (!ticking) {
//         window.requestAnimationFrame(updateScrollDir);
//         ticking = true;
//       }
//     };

//     /**
//      * Bind the scroll handler if `off` is set to false.
//      * If `off` is set to true reset the scroll direction.
//      */
//     !off
//       ? window.addEventListener("scroll", onScroll)
//       : setScrollDir(initialDirection);

//     return () => window.removeEventListener("scroll", onScroll);
//   }, [initialDirection, thresholdPixels, off]);

//   return scrollDir;
// };

// export const useInfiniteScroll = (callback) => {
//   const [isFetching, setIsFetching] = useState(false);

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   useEffect(() => {
//     if (!isFetching) return;
//     callback(() => {

//     });
//   }, [isFetching]);

//   function handleScroll() {
//     if (
//       window.innerHeight + document.documentElement.scrollTop !==
//         document.documentElement.offsetHeight ||
//       isFetching
//     )
//       return;
//     setIsFetching(true);
//   }

//   return [isFetching, setIsFetching];
// };

export const validEmail5 = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.\\.[a-zA-Z]+$"
);

export const defaultPicUrl =
  "https://tknight-media.s3.amazonaws.com/profile.png";

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function generateGreetings(targetCustomer) {
  var currentHour = moment().format("HH");

  if (targetCustomer && targetCustomer.name) {
    if (currentHour >= 3 && currentHour < 12) {
      return "morning";
    } else if (currentHour >= 12 && currentHour < 15) {
      return "afternoon";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "evening";
    } else if (currentHour >= 20 || currentHour < 3) {
      return "evening";
    } else {
      return "early morning";
    }
  } else {
    if (currentHour >= 3 && currentHour < 12) {
      return "Morning";
    } else if (currentHour >= 12 && currentHour < 15) {
      return "Afternoon";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "Evening";
    } else if (currentHour >= 20 || currentHour < 3) {
      return "Evening";
    } else {
      return "Early Morning";
    }
  }
}
export function getKeyboardHeight() {
  return localStorage.getItem("keyboardHeight") || 0;
}

export function getTopbarHeight() {
  const topbarHeight = localStorage.getItem("topbarHeight");
  return topbarHeight && topbarHeight >= 10 ? topbarHeight - 10 : 0;
}

export function formatViewCount(viewCount) {
  if (viewCount < 1000) {
    return viewCount;
  } else if (viewCount < 1000000) {
    return viewCount % 1000 < 100
      ? Math.floor(viewCount / 1000) + "K"
      : (viewCount / 1000).toFixed(1) + "K";
  } else {
    return viewCount % 1000000 < 100000
      ? Math.floor(viewCount / 1000000) + "M"
      : (viewCount / 1000000).toFixed(1) + "M";
  }
}

export const countries = [
  "",
  "Argentina",
  "Australia",
  "Austria",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "China",
  "Hungary",
  "India",
  "Indonesia",
  "Ireland",
  "Italy",
  "Japan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Malta",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Pakistan",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Serbia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Thailand",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
];
