import React, { useState } from "react";
import { useWindowSize } from "../../util/hooks";
import XPBubble from "./XPBubble";
// const defaultPicUrl = "https://tknight-media.s3.amazonaws.com/profile.png";

function BattleEndCanvas({ endedAt, deltaXP }) {
  const size = useWindowSize();
  const [pageLoadedAt] = useState(new Date());

  return (
    <div className="w-[84%] sm:w-[88%] inset-y-0 p-0 h-full absolute overflow-hidden ">
      <div className="mt-0.5 w-full h-full relative overflow-hidden">
        {deltaXP !== null && deltaXP !== 0 && (
          <XPBubble
            deltaXP={deltaXP}
            offset={size.width >= 768 ? 15 : 3}
            pageLoadedAt={pageLoadedAt}
            curAnsEndsAt={endedAt}
          />
        )}
      </div>
      <div className="w-full h-6 short:h-3 bg-gradient-to-b absolute top-0  from-darker-gray   to-transparent"></div>
      <div className="w-full absolute bottom-0 h-6 short:h-3 bg-gradient-to-t from-darker-gray to-transparent"></div>
    </div>
  );
}

export default BattleEndCanvas;
